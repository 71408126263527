.Toastify__toast-container--top-right {
  top: 65px;
}
.Toastify__toast {
  min-height: 50px;
  opacity: 0.9;
  --animate-duration: 0.3s;
  word-break: break-word;
}

/* マイページの通知*/
.Toastify__toast--default {
  background-color: #ffffff;
  color: #686c68;
  opacity: 1;
}

/* aidemy color , 基本の受講者画面の通知*/
.Toastify__toast--info {
  background-color: #1da69a !important;
}

.Toastify__toast--success {
  background-color: #51a351 !important;
}

.Toastify__toast--warning {
  background-color: #ff4b00 !important;
}

.Toastify__toast--error {
  background-color: #bd362f !important;
}
