html.exercise {
  -ms-overflow-style: none;
}
html.exercise::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Noto Sans JP", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  overflow: auto;
}
@media only screen and (max-width: 896px) {
  body {
    position: initial;
  }
}
button,
button * {
  transition: all 150ms ease-out;
}
a {
  text-decoration: none;
}
a,
a * {
  transition: all 150ms ease-out;
}
code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}
b,
.color--keyword {
  color: #f44336 !important;
}
.math {
  overflow: hidden !important;
  background: #fafafa;
  padding: 0 1em;
  margin: 1em 0;
}
.math .katex-display {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}
hr {
  margin: 2.2em 0;
  border-right: transparent;
  border-left: transparent;
  border-bottom: transparent;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

/* custom react-datepicker */
.react-datepicker-popper {
  z-index: 3 !important;
}
.react-datepicker__day--in-range {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__day--selected {
  background-color: #ccc !important;
  color: #fff !important;
}
.react-datepicker__day:hover {
  background-color: #216ba5 !important;
  color: #fff !important;
}

/* medium-zoom の zoom 時の z-index 指定 */
.medium-zoom-overlay {
  z-index: 200;
}
.medium-zoom-image {
  z-index: 201;
}
